import { Fragment, useContext, useState, useEffect } from "react";
import SideBar from "../UI/Sidebar/SideBar";
import classes from "./Donation.module.css";
import { db } from "../../store/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import AuthContext from "../../store/auth-context";
import Loading from "../UI/Loading/Loading";
import Layout from '../Layout/Layout'

const Donation = () => {
  const authctx = useContext(AuthContext);
  const usersRef = doc(db, "regUsers", authctx.loggedInUserId);
  const [user, setUser] = useState({});
  const [loading, isLoading] = useState(false);
  const [pancard,setpancard]=useState(false)
  const getUserData = async () => {
    isLoading(true);
    let docdata = await getDoc(usersRef);
    if (docdata.exists()) {
      console.log(docdata.data());
      setUser(docdata.data());
      isLoading(false);
    } else {
      console.log("No such document");
    }
  };
  const showpancard=(event)=>{
    console.log(event.target.checked)
    setpancard(event.target.checked)
  }
  const handledonation=(event)=>{
    event.preventDefault();
    console.log("donnateeee")
  }
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Layout>
      {loading && <Loading />}
      {!loading && (
        <SideBar>
          <div className={classes.donationform}>
            <div className={classes.donationscreen}>
              <div className={classes.donationheading}>
                <h2 className={classes.donationheader}>Donation Form</h2>
                <form className={classes.donationbody}>
                  <div className={classes.donationfield}>
                    <label className={classes.donationLabel} htmlFor="name">
                      Name:
                    </label>
                    &nbsp;&nbsp;
                    <input
                      type="text"
                      className={classes.donationinput}
                      defaultValue={user.prezname}
                      readOnly
                    />
                  </div>
                  <div className={classes.donationimage}>
                    <img src={user.image}/>
                  </div>
                  <div className={classes.donationfield}>
                    <label className={classes.donationLabel} htmlFor="email">
                      Email: 
                    </label>
                    &nbsp;&nbsp;
                    <input
                      type="email"
                      className={classes.donationinput}
                      placeholder="Enter Email"
                      defaultValue={user.email}
                      readOnly
                    />
                  </div>
                  <div className={classes.donationfield}>
                    <label className={classes.donationLabel} htmlFor="phone">
                      Phone No: 
                    </label>
                    &nbsp;&nbsp;
                    <input
                      type="number"
                      className={classes.donationinput}
                      defaultValue={user.phone}
                      readOnly
                    />
                  </div>
                  <div className={classes.donationfield}>
                    <label className={classes.donationLabel} htmlFor="whatsapp">
                      Whatsapp No: 
                    </label>
                    &nbsp;&nbsp;
                    <input
                      type="number"
                      className={classes.donationinput}
                      defaultValue={user.whatsapp}
                      readOnly
                    />
                  </div>
                  <div className={classes.donationfield}>
                    <label className={classes.donationLabel} htmlFor="organization">
                      Organization: 
                    </label>
                    &nbsp;&nbsp;
                    <input
                      type="text"
                      className={classes.donationinput}
                      defaultValue={user.name}
                      readOnly
                    />
                  </div>
                  <div className={classes.donationaddressfield}>
                    <div className={classes.donationaddressLabel}>
                    <label htmlFor="address">
                      Address: 
                    </label>
                    </div>
                    <textarea
                       rows="2"
                      cols="40"
                      className={classes.donationtextarea}
                      defaultValue={user.address}
                      readOnly
                    />
                    
                  </div>
                  <div className={classes.donationfield}>
                    <label className={classes.donationLabel} htmlFor="mid">
                      Member Id: 
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      className={classes.donationinput}
                      defaultValue={user.mid}
                      readOnly
                    />
                  </div>
                  <div className={classes.donationfield}>
                    <label className={classes.donationLabel} htmlFor="amount">
                      Amount: 
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      className={classes.donationinput}
                      placeholder=" Enter Amount"
                    />
                  </div>
                  <div className={classes.donationfield}>
                  <label className={classes.donationLabel} htmlFor="80g">
                      Avail For 80G: 
                    </label>
                    <input
                      type="checkbox"
                      onClick={showpancard}
                    />
                  </div>
                  {pancard &&
                  <div className={classes.donationfield}>
                    <label className={classes.donationLabel} htmlFor="pancard">
                      Pancard: 
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      className={classes.donationinput}
                      placeholder=" Enter PanNumber"
                    />
                  </div>
                  }
                  <div className={classes.donationbuttondiv}>
                    <button className={classes.donatebutton} onclick={handledonation} type="submit">Donate</button>
                  </div>
                </form>
              </div>
              <div className={classes["donationscreen__background"]}>
                <span
                  className={classes["donationscreen__background__shape4"]}
                ></span>
                <span
                  className={classes["donationscreen__background__shape3"]}
                ></span>
                <span
                  className={classes["donationscreen__background__shape2"]}
                ></span>
                <span
                  className={classes["donationscreen__background__shape1"]}
                ></span>
              </div>
            </div>
          </div>
        </SideBar>
      )}
    </Layout>
  );
};

export default Donation;
