import React, { Fragment, useState, useContext, useEffect,useRef } from "react";
import classes from "./ContactDetail.module.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../../store/firebase-config";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import AuthContext from "../../../store/auth-context";
import StepperUI from "../../UI/Stepper/Stepper";
import Loading from '../../UI/Loading/Loading';


const ContactDetail = (props) => {
  const axios = require("axios");
  const [isChecked, setIsChecked] = useState(false);
  const [whatsappNumberValue, setWhatsppNumberValue] = useState("");
  const [userId, setUserId] = useState(0);
  const [updatedUser, setUpdatedUser] = useState(0);
  const authctx = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const enteredRefOTP = useRef();
  const updatememberId = location.state.unregisteredUser.memberId;
  const [errorWhatsaapnumber, seterrorWhatsaapnumber] = useState("");
  const [successWhatsappnumber, setsuccessWhatsappnumber] = useState("");
  const [openotpwhatsapp, setopenotpwhatsapp] = useState("");
  const [OTP, setOTP] = useState();
  const [errormessage,setErrorMessage]=useState('')
  const [successmessage,setSuccessMessage]=useState('')
  const [loading,setLoading]=useState(false)
  const register = async () => {
    console.log("registerrrr");
    setLoading(true)
    const enteredEmail = props.values.orgemail;
    const password = props.values.orgPassword;
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        enteredEmail,
        password
      );
      if (user) {
        const expirationTime = new Date(
          new Date().getTime() + +user._tokenResponse.expiresIn * 1000
        );
        authctx.login(
          user._tokenResponse.idToken,
          expirationTime.toISOString()
        );
        authctx.setLoggedInUserId(user.user.uid);
        props.handleChange("userid", user.user.uid);
      }
      setUserId(user.user.uid);
    } catch (error) {
      console.log(error.message);
    }
  };
  const createUser = async (userId) => {
    const refdoc = doc(db, "regUsers", userId);
    const data = await setDoc(refdoc, props.values);
    setUpdatedUser(1);
  };
  const updateregisteredUser = async () => {
    const userDoc = doc(db, "users", location.state.unregisteredUser.id);
    const newFields = { [updatememberId.toString()]: "r" };
    await updateDoc(userDoc, newFields);
    navigate("/", { replace: true });
    setLoading(false)
  };

  useEffect(() => {
    if (userId) {
      
      createUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (updatedUser) {
      console.log(updatedUser);
      updateregisteredUser();
    }
  }, [updatedUser]);

  useEffect(()=>{
    if(props.values.orgwhatsapp){
      delete props.values.orgwhatsapp;
    }
  },[isChecked])

  const handleInputChange = (event) => {
    console.log(event.target.value);
    console.log(event.target.id);
    if (event.target.id === "orgwhatsappsamephone") {
      if (event.target.checked) {
        setIsChecked(true);
        props.handleChange(event.target.id, "true");
        setWhatsppNumberValue(props.checkUser.phoneNumber);
      } else {
        setIsChecked(false);
        setWhatsppNumberValue("");
        props.handleChange(event.target.id, "false");
      }
    }
    if (event.target.id === "orgwhatsapp") {
      if (isChecked) {
        setWhatsppNumberValue(props.checkUser.phoneNumber);
      } else {
        let whatsapp = event.target.value;
        setIsChecked(false);
        setWhatsppNumberValue(props.values.orgwhatsapp);
        props.handleChange(event.target.id, event.target.value);
        if (whatsapp.length > 10) {
          seterrorWhatsaapnumber("Number Cannot be greater than 10 digit");
          setsuccessWhatsappnumber("");
        } else if (whatsapp.length < 10) {
          seterrorWhatsaapnumber("Number Cannot be lesser than 10 digit");
          setsuccessWhatsappnumber("");
        } else {
          setsuccessWhatsappnumber("10 digit number entered!!");
          seterrorWhatsaapnumber("");
        }
      }
    }
  };

  const prevPage = () => {
    props.prevStep();
  };
  const confirmandsignup = (event) => {
    event.preventDefault();
    register();
  };
  const generateOTP = () => {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  };
  const submittosendotptowhatsapp = (event) => {
    event.preventDefault();
    setopenotpwhatsapp("entered1");
    let setNewOTP = generateOTP();
    setOTP(setNewOTP);
    let message = `Dear JITA User, OTP is ${setNewOTP} for Registration in JITA App vide Membership No. ${props.values.mid}.`;
    axios
      .get(
        `https://smshorizon.co.in/api/sendsms.php?user=pankhudisoftware&apikey=Df8MOFEsDLDp9ZLsECPt&mobile=${props.values.orgwhatsapp}&message=${message}&senderid=JITAIN&type=txt&tid=1207161763243940164`
      )
      .then((res) => {
        console.log(res);
        console.log("hiiiaaaa");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verifyWhatsapp=(event)=>{
    event.preventDefault();
    let enteredOTP = enteredRefOTP.current.value;
    if (OTP === enteredOTP) {
      setSuccessMessage('Whatsapp Verified!!. Please Confirm And Signup!!')
    }else{
      setErrorMessage('Please Enter Correct OTP')
    }
  }

  return (
    <Fragment>
      {loading && <Loading/>}
      {!loading && 
      <div className={classes.contactcontainer}>
        <div className={classes.contactscreen}>
          <div className={classes["screen__content"]}>
            <form className={classes.contactDetail}>
              <StepperUI active='3'/>
              <h2
                style={{ textAlign: "center" }}
                className={classes.contactHeading}
              >
                Contact Details
              </h2>
              <div className={classes["contact__field"]}>
                <label className={classes.contactlabel}>
                  Organization Phone Number
                </label>
                <input
                  id="orgphone"
                  type="text"
                  className={classes["contact__input"]}
                  defaultValue={props.checkUser.phoneNumber}
                  readOnly={true}
                />
              </div>
              <div className={classes["contact__field"]}>
                <label className={classes.contactlabel}>
                  Same as Orgnization Phone Number*
                </label>
                <input
                  id="orgwhatsappsamephone"
                  type="checkbox"
                  onChange={handleInputChange}
                />
              </div>
              {!isChecked && (
                <div className={classes["contact__field"]}>
                  <label className={classes.contactlabel}>
                    Organization Whatsapp Number*
                  </label>
                  <input
                    id="orgwhatsapp"
                    type="text"
                    className={classes["contact__input"]}
                    placeholder="Enter Whatsapp Number"
                    onChange={handleInputChange}
                  />
                  {errorWhatsaapnumber && (
                    <div className={classes.errormessage}>
                      {errorWhatsaapnumber}
                    </div>
                  )}
                  {successWhatsappnumber && (
                    <div className={classes.successmessage}>
                      {successWhatsappnumber}
                    </div>
                  )}
                </div>
              )}
              {!isChecked && (
                <div className={classes.odbutton}>
                  <button
                    className={classes["contact__submit"]}
                    onClick={submittosendotptowhatsapp}
                  >
                    Submit to Send OTP
                  </button>
                </div>
              )}
              {openotpwhatsapp && (
                <div>
                <div className={classes["contact__field"]}>
                  <label className={classes.contactlabel}>Enter OTP</label>
                  <input
                    type="text"
                    className={classes["contact__input"]}
                    placeholder="Enter Whatsapp OTP"
                    ref={enteredRefOTP}
                  />
                </div>
                {errormessage && (
                    <div className={classes.errormessage}>
                      {errormessage}
                    </div>
                  )}
                  {successmessage && (
                    <div className={classes.successmessage}>
                      {successmessage}
                    </div>
                  )}
                <div className={classes.odbutton}>
                <button
                  className={classes["contact__submit"]}
                  onClick={verifyWhatsapp}
                >
                  Verify Whatsapp
                </button>
              </div>
              </div>
              )}

              <div className={classes.odbutton}>
                <button
                  className={classes["contact__submit"]}
                  onClick={prevPage}
                >
                  Previous Page
                </button>
              </div>
              <div className={classes.odbutton}>
                <button
                  className={classes["contact__submit"]}
                  type="submit"
                  onClick={confirmandsignup}
                >
                  Confirm And SignUp
                </button>
              </div>
            </form>
          </div>
          <div className={classes["screen__background"]}>
            <span className={classes["screen__background__shape4"]}></span>
            <span className={classes["screen__background__shape3"]}></span>
            <span className={classes["screen__background__shape2"]}></span>
            <span className={classes["screen__background__shape1"]}></span>
          </div>
        </div>
      </div>
        }
    </Fragment>
  );
};

export default ContactDetail;
