import React, { Fragment, useEffect, useState } from "react";
import { db } from "../../store/firebase-config";
import { collection, doc, getDocs } from "firebase/firestore";
import MemberDisplay from "./MemberDisplay";
import Loading from "../UI/Loading/Loading";
import classes from "./MemberDirectory.module.css";
import SideBar from "../UI/Sidebar/SideBar";
import Layout from "../Layout/Layout";

const MemberDirectory = () => {
  const usersRef = collection(db, "regUsers");
  const [user, setUser] = useState([]);
  const [loading, isLoading] = useState(false);
  const [userLoaded, isUserLoaded] = useState([]);
  const getUserData = async () => {
    isLoading(true);
    const docdata = await getDocs(usersRef);
    setUser(docdata.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    isUserLoaded(docdata.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    isLoading(false);
  };
  useEffect(() => {
    console.log("userlog");
    getUserData();
  }, []);

  const searchUser = (event) => {
    console.log(event.target.value);
    let filterName = user.filter((username) =>
      username.orgname.toLowerCase().includes(event.target.value.toLowerCase())
    );
    if (filterName) {
      isUserLoaded(filterName);
    } else {
      isUserLoaded(user);
    }
  };
  return (
    <Layout>
      {loading && <Loading />}
      {!loading && (
        <SideBar>
          <div className={classes.memberdirectory}>
            <input
              className={classes.search}
              placeholder="Member Search"
              type="search"
              onChange={searchUser}
            />
            <div className={classes.row}>
              {userLoaded.map((user) => {
                return (
                  <div className={classes.column}>
                    <MemberDisplay id={user.id} user={user} />
                  </div>
                );
              })}
            </div>
          </div>
        </SideBar>
      )}
    </Layout>
  );
};
export default MemberDirectory;
