import React, { useCallback, useState,useEffect } from "react";
let logoutTimer;
const AuthContext=React.createContext({
    token:'',
    isLoggedIn:false,
    login:(token)=>{},
    logout:()=>{},
    isMemberExist:false,
    checkMember:(memberId)=>{},
    loggedInUserId:'',
    setLoggedInUserId:(userId)=>{}
})

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();
    const remainingDuration = adjExpirationTime - currentTime;
    return remainingDuration; 
  };
  
  const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expirationTime');
  
    const remainingTime = calculateRemainingTime(storedExpirationDate);
  
    if (remainingTime <= 3600) {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
      localStorage.removeItem('userId');
      return null;
    }
  
    return {
      token: storedToken,
      duration: remainingTime,
    };
  };

  const retrieveStoredUserId=()=>{
    const storeduserId = localStorage.getItem('userId');
    return storeduserId
  }

  export const AuthContextProvider=(props)=>{
    const tokenData = retrieveStoredToken();
    const userIdData=retrieveStoredUserId();
    const[memberexist,setMemberExists]=useState(false)
    
   // let memberexist=false;
    let initialToken;
    if (tokenData) {
      initialToken = tokenData.token;
    }
    
    let initialUserId;
    if(userIdData){
      initialUserId=userIdData;
    }
  
    const [loggedUser,setLoggedUser]=useState(initialUserId);
    const [token, setToken] = useState(initialToken);
  
    const userIsLoggedIn = !!token;//giving false
  
    const logoutHandler = useCallback(() => {
      setToken(null);
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
      localStorage.removeItem('userId');
      setLoggedUser('');
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      
    }, []);
  
    const loginHandler = (token, expirationTime) => {
      setToken(token);
      localStorage.setItem('token', token);
      localStorage.setItem('expirationTime', expirationTime);
  
      const remainingTime = calculateRemainingTime(expirationTime);
  
      logoutTimer = setTimeout(logoutHandler, remainingTime);
    };

    const checkMemberHandler=(memberId)=>{
      console.log(memberId);
      if(memberId){
        setMemberExists(true)
      }
    }

    const setLoggedInUserIdHandler=(userId)=>{
      if(userId){
        setLoggedUser(userId);
        localStorage.setItem('userId', userId);
      }
    }
  
    useEffect(() => {
      if (tokenData) {
        console.log(tokenData.duration);
        logoutTimer = setTimeout(logoutHandler, tokenData.duration);
      }
    }, [tokenData, logoutHandler]);
  
    const contextValue = {
      token: token,
      isLoggedIn: userIsLoggedIn,
      login: loginHandler,
      logout: logoutHandler,
      checkMember:checkMemberHandler,
      isMemberExist:memberexist,
      setLoggedInUserId:setLoggedInUserIdHandler,
      loggedInUserId:loggedUser
    };
    return(
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
  }

  export default AuthContext;