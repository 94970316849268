import {Fragment} from 'react'
import classes from './ImagePreview.module.css'
const ImagePreview=(props)=>{
    console.log(props.file)
    return(
        <Fragment>
            {/* <h1>No photos Selected</h1> */}
        {props.file==null && <h3 className={classes.ImagePreviewheading}>No photos Selected</h3>}
        {props.file && Object.entries(props.file).map(item => {
             return( 
             <Fragment>
             <img className={classes.imagepreviewmultiple} alt="Preview" src={URL.createObjectURL(item[1])} />
             </Fragment>) 
            
        })}
        </Fragment>
    )
}

export default ImagePreview;