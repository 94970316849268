import React,{Fragment,useState} from 'react';
import OrganizationDetail from './OrganizationalDetail';
import OtherOrgDetail from './OtherOrgDetail';
import ContactDetail from './ContactDetail';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SignupForOrg=()=>{
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location);
    console.log(location.state.unregisteredUser);
    const [step,setStep]=useState(1);
    const [inputValues,setInputValues]=useState({
        orgcategory:location.state.unregisteredUser.type,
        orgtype:'None',
        orgstate:'JHARKHAND',
        orggstinreg:'false',
        regDate:new Date().toLocaleString(),
        paysystem:'yearly',
        lastpay:new Date().toLocaleString(),
        lastpayamt:'0',
        image:"https://firebasestorage.googleapis.com/v0/b/auth-react-app-8fddb.appspot.com/o/users%2FDefault_Image.png?alt=media&token=03f28728-52e1-416d-b4a2-b22ef92fa4b5",
        orgphone:location.state.unregisteredUser.phoneNumber,
        mid:location.state.unregisteredUser.memberId
    })
    const nextStep=()=>{
        setStep(prev=> prev+1)
    }
    const prevStep=()=>{
        setStep(prev=> prev-1)
    }
    const handleChange=(name,value)=>{
        setInputValues({...inputValues,[name]:value})
    }
    console.log(inputValues)
    return(
        <Fragment>
        {(() => {
            switch(step){
                case 1:return <OrganizationDetail
                    nextStep={nextStep}
                    handleChange={handleChange}
                    values={inputValues}
                    checkUser={location.state.unregisteredUser}
                />
                case 2:return <OtherOrgDetail
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    values={inputValues}
                    checkUser={location.state.unregisteredUser}
                />
                case 3:return <ContactDetail
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    values={inputValues}
                    checkUser={location.state.unregisteredUser}
                />
            }
        })()}
        </Fragment>
    )
}

export default SignupForOrg;