import React, { useState } from "react";
//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
//import icons from react icons

import { FiHome, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import PersonIcon from "@mui/icons-material/Person";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import DvrSharpIcon from "@mui/icons-material/DvrSharp";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventIcon from "@mui/icons-material/Event";
import UpdateIcon from "@mui/icons-material/Update";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
// import "./Header.css";
import "./SideBar.css";

const SideBar = (props) => {
  // const navigate=useNavigate();
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  return (
    <>
      <div id="sidebarcontent">
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="closemenu" onClick={menuIconClick}>
              {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem active={true} icon={<FiHome />}>
                My Profile
                <Link to="/profile" />
              </MenuItem>
              <MenuItem icon={<PersonIcon />}>
                Member Directory
                <Link to="/memberdirectory" />
              </MenuItem>
              <MenuItem icon={<DvrSharpIcon />}>E-NewsLetter</MenuItem> 
              <MenuItem icon={<NewspaperIcon />}>NewsLetter</MenuItem>
              <MenuItem icon={<PhotoLibraryIcon />}>
                Photo Gallery
              <Link to="/photogallery"/>
              </MenuItem>
              <MenuItem icon={<DashboardIcon />}>Notice Board</MenuItem>
              <MenuItem icon={<EventIcon />}>Event Portal</MenuItem>
              <MenuItem icon={<UpdateIcon />}>
                Donation
                <Link to="/donation" />
              </MenuItem>
              <MenuItem icon={<PersonAddIcon />}>Update Profile</MenuItem>
              <MenuItem icon={<BloodtypeIcon />}>Blood Bank</MenuItem>
            </Menu>
          </SidebarContent>
        </ProSidebar>
        
      </div>
      <div class="main">{props.children}</div>
      </div>
    </>
  );
};

export default SideBar;
