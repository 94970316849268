import { useState, useEffect, Fragment } from "react";
import SideBar from "../UI/Sidebar/SideBar";
import classes from "./PhotoGallery.module.css";
import AddPhotos from "./AddPhotos";
import {db} from '../../store/firebase-config'
import { getDocs,collection } from "firebase/firestore";
import PhotoGalleryViewer from "./PhotoGalleryViewer";
import Loading from "../UI/Loading/Loading";
import Layout from '../Layout/Layout';

const PhotoGallery = () => {
  const [modalShow, SetModalShow] = useState(false);
  const currentYear = new Date().getFullYear();
  const [gallerydocs, setGalleryDocs] = useState([]);
  const [displaygallery,setDisplayGallery]=useState([])
  const[loading,isLoading]=useState(false)
  const changeSelectedYear = (event) => {
    //setSelectedYear(event.target.value)
    console.log(event.target.value)
    setDisplayGallery([])
    if(gallerydocs){
      setDisplayGallery(gallerydocs.filter((doc)=>{
        if(doc.year===+event.target.value){
          return doc;
        }
      })
      )
    }
    
  };
  const onInitialLoad = () => {
    if(gallerydocs){
      setDisplayGallery(gallerydocs.filter((doc)=>{
        if(doc.year===currentYear){
          return doc;
        }
      })
      )
     }
  };

  console.log(displaygallery)

  const showprofileImageChange = () => {
    console.log("hiiiii");
    SetModalShow(true);
  };
  const hideprofileImageChange = () => {
    SetModalShow(false);
  };

  useEffect(() => {
    const getGalleryocument = async () => {
      isLoading(true)
      const usersCollectionRef = collection(db, "gallery");
      const data = await getDocs(usersCollectionRef);
      setGalleryDocs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      isLoading(false)
    };
    getGalleryocument();
  }, []);

  useEffect(()=>{
    onInitialLoad();
  },[gallerydocs])

  return (
    <Layout>
      {loading && <Loading/>}
      {modalShow && !loading && <AddPhotos onClose={hideprofileImageChange}/>}
    {!loading && !modalShow && 
      <SideBar>
        <div className={classes.photogallery}>
        <label className={classes.photogalleryLabel} htmlFor="years">
          Choose Year:
        </label>
        <select
          className={classes.photogalleryselect}
          name="years"
          id="years"
          onChange={changeSelectedYear}
        >
          <option value={currentYear}>{currentYear}</option>
          <option value={currentYear - 1}>{currentYear - 1}</option>
          <option value={currentYear - 2}>{currentYear - 2}</option>
          <option value={currentYear - 3}>{currentYear - 3}</option>
          <option value={currentYear - 4}>{currentYear - 4}</option>
          <option value={currentYear - 5}>{currentYear - 5}</option>
          <option value={currentYear - 6}>{currentYear - 6}</option>
        </select>
        <button
          className={classes.photogalleryButton}
          onClick={showprofileImageChange}
        >
          Add Photos
        </button>
        
        <div className={classes.photoGalleryRow}> 
          {displaygallery.map(gallery=>{
            return (<div className={classes.photoGalleryColumn}><PhotoGalleryViewer id={gallery.id} gallery={gallery}/></div>)
          })}
        </div>
        </div>
      </SideBar>
      }
    </Layout>
  );
};
export default PhotoGallery;
