import { useRef, useContext,useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
 import classes from "./Login.module.css";
import AuthContext from "../../../store/auth-context"; 
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import LoginIcon from "@mui/icons-material/Login";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { auth } from "../../../store/firebase-config"; 
import { signInWithEmailAndPassword} from "firebase/auth";
import Loading from '../../UI/Loading/Loading';
import Logo from './Dhanbad_Club_Logo.png'
const Login = () => {
  let navigate = useNavigate();
  const authctx = useContext(AuthContext);
  const emailInputref = useRef();
  const passwordInputref = useRef(); 
  const [loaded,isLoading]=useState(false);
  const [errormessage,setErrorMessage]=useState("")
  const loginUser = async (enteredEmail, enteredPassword) => {
    isLoading(true);
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        enteredEmail,
        enteredPassword
      );
      if (user) {
        console.log(user);
        const expirationTime = new Date(
          new Date().getTime() + +user._tokenResponse.expiresIn * 1000
        );
        authctx.login(
          user._tokenResponse.idToken,
          expirationTime.toISOString()
        );
        authctx.setLoggedInUserId(user.user.uid);
        isLoading(false)
        navigate("/", { replace: true });
      }
      console.log(user);
      console.log(user._tokenResponse);
    } catch (error) {
      isLoading(false)
      console.log(error.message);
      setErrorMessage("Please Enter Correct Email/Password")
    }
  };

  const formSumbitHandler = (event) => {
    event.preventDefault();
    const enteredEmailAddress = emailInputref.current.value;
    const enteredPassword = passwordInputref.current.value;
    console.log(enteredEmailAddress);
    console.log(enteredPassword);
    loginUser(enteredEmailAddress, enteredPassword);
  };

  const UserCheck=()=>{
    navigate("/usercheck", { replace: true });
  }

  return (
    <Fragment>
      {loaded && <Loading/>}
      {!loaded &&
      <div className={classes.logincontainer}>
        <div className={classes.loginscreen}> 
          <div className={classes["screen__content"]}> 
            <form className={classes.login}> 
            <img src={Logo} height={100} width={300}/>
              <h2 className={classes.loginheading}>Login</h2>
              <div className={classes["login__field"]}>
                <input
                  type="email"
                  className={classes["login__input"]}
                  placeholder="Email"
                  ref={emailInputref} 
                />
                <EmailIcon />
              </div>
              <div className={classes["login__field"]}>
                <input
                  type="password"
                  className={classes["login__input"]}
                  placeholder="Password"
                  ref={passwordInputref}
                />
                <KeyIcon />
              </div>
              {errormessage && (
                  <div className={classes.errormessage}>{errormessage}</div>
                )}
              <button className={classes["login__submit"]} type="submit" onClick={formSumbitHandler}>
                <span className={classes["button__text"]}>Log In Now</span>
                <LoginIcon />
              </button>
              <button className={classes["login__submit"]} type="submit" onClick={UserCheck}>
                <span className={classes["button__text"]}>Check User Registration</span>
                <ArrowForwardIcon />
              </button>
            </form>
          </div>
          <div className={classes["screen__background"]}>
            <span className={classes["screen__background__shape4"]}></span>
            <span className={classes["screen__background__shape3"]}></span>
            <span className={classes["screen__background__shape2"]}></span> 
            <span className={classes["screen__background__shape1"]}></span>
          </div>
        </div>
      </div>}
    </Fragment>
  );
};
export default Login;
