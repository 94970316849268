import React, { Fragment,useEffect,useState,useContext,useRef } from "react";
import Modal from "../UI/Modal/Modal";
import classes from "./AddPhotos.module.css";
import ImagePreview from "./ImagePreview";
import {getStorage,ref,uploadBytes,getDownloadURL} from "firebase/storage"
import AuthContext from "../../store/auth-context";
import Loading from "../UI/Loading/Loading";
import { doc,setDoc,getDoc } from "firebase/firestore";
import { nanoid } from 'nanoid'
import {db} from '../../store/firebase-config'

const AddPhotos = (props) => {
  const authctx=useContext(AuthContext);
  const currentYear = new Date().getFullYear();
  const [selectedPhotos,setSelectedPhotos]=useState(null)
  const [selectedPhotosLength,setSelectedPhotosLength]=useState()
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const [cssstyle,setcssstyle]=useState({"height":"auto"})
  const storage=getStorage();
  const [selectedYear,setSelectedYear]=useState(currentYear);
  const captionInputref=useRef();
  const uniqueId=nanoid();
  const [loading,isLoading]=useState(false)
  const [users, setUsers] = useState([]);
  const[ captionentered,setCaptionEntered]=useState('');
  const[storageid,useStorageid]=useState();
  const selectfile=()=>{
      document.getElementById("getFile").click()
  }
  const multipleImageUpload=(event)=>{ 
      setSelectedPhotos(event.target.files)
      setSelectedPhotosLength(event.target.files.length)
      for (let i = 0; i < event.target.files.length; i++) {
        const newImage = event.target.files[i];
        newImage["id"] = Math.random();
        setImages((prevState) => [...prevState, newImage]);
      }
    }
  const UploadMultipleImage=()=>{
    setCaptionEntered(captionInputref.current.value);
    isLoading(true)
    images.map(async(image) => {
      const fileRef=ref(storage,`gallery/${authctx.loggedInUserId}/${uniqueId}/${image.name}`)
      const snapshot= await uploadBytes(fileRef,image)
      const photoURL= await getDownloadURL(fileRef)
        setUrls((prevState) => [...prevState, photoURL]);
    });
    useStorageid(uniqueId)
  }
  const selectedYearHandler=(e)=>{
    setSelectedYear(e.target.value);
  }


  useEffect(()=>{
    const insertDataforgallery=async()=>{
      const imageobj={}
      for(let i=0;i<selectedPhotosLength;i++){
        imageobj['image'+i]=urls[i]
      }
      console.log(storageid)
       const documentToInsert={
        caption:captionentered,
        id:storageid,
        year:+selectedYear,
        name:users.prezname,
        time:new Date().toLocaleString(),
        userid:authctx.loggedInUserId,
        length:selectedPhotosLength,
        images:imageobj
      }
      const refdoc=doc(db, "gallery",storageid) 
      const data = await setDoc(refdoc, documentToInsert)
      isLoading(false)
      props.onClose();      
    }
    if(selectedPhotosLength>0 && selectedPhotosLength===urls.length){
      insertDataforgallery();
    }
    
  },[urls])

  useEffect(()=>{
    if(selectedPhotosLength>0){
      setcssstyle({"height":"70%","overflow-y":"scroll"})
    }else{
      setcssstyle({"height":"auto"})
    }
  },[selectedPhotosLength])

  useEffect(()=>{
    const getUsers = async () => {
      const docRef = doc(db, "regUsers", authctx.loggedInUserId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setUsers(docSnap.data());
      }
    };
    getUsers();
  },[])

  return (
    <Fragment>
      {loading && <Loading/>}
      {!loading && 
      <Modal onClose={props.onClose} style={cssstyle}>
        <div>
          <h1 className={classes.addPhotoHeader}>Upload Image</h1>
          <br></br>
          <label className={classes.addPhotoLabel} htmlFor="selectYear">
            Select Year:*
          </label>
          <select
            className={classes.addPhotoselect}
            name="selectYear"
            id="selectYear"
            autoFocus={true}
            onChange={selectedYearHandler}
          >
            <option value={currentYear}>{currentYear}</option>
            <option value={currentYear - 1}>{currentYear - 1}</option>
            <option value={currentYear - 2}>{currentYear - 2}</option>
            <option value={currentYear - 3}>{currentYear - 3}</option>
            <option value={currentYear - 4}>{currentYear - 4}</option>
            <option value={currentYear - 5}>{currentYear - 5}</option>
            <option value={currentYear - 6}>{currentYear - 6}</option>
          </select>
          <br></br>
          <br></br>
          <label className={classes.addPhotoLabel} htmlFor="caption">
            Caption:
          </label>
          <br></br>
          <br></br>
          <textarea
            className={classes.addPhototextarea}
            id="caption"
            name="caption"
            rows="4"
            cols="60"
            autoFocus={true}
            placeholder="Please Enter Some Text"
            ref={captionInputref}
          />
          <br></br>
          <br></br>
          <label className={classes.addPhotoLabel} htmlFor="addmultiplephoto">Add Photos (Max 25 files are allowed) *</label>
          <br></br>
          <br></br>
          <button
            className={classes.addEnablePhotobutton}
            onClick={selectfile}
          >
           Select Files
          </button>
          <br></br>
          <br></br>
          <input className={classes.addphotoinputfile} type="file" id="getFile" accept="image/*" multiple onChange={multipleImageUpload}/>
          <br></br>
          <ImagePreview file={selectedPhotos} fileLength={selectedPhotosLength} />
          <br></br>
          <br></br>
          <button
            className={selectedPhotosLength>0?classes.addEnablePhotobutton:classes.disabledbutton}
            onClick={UploadMultipleImage}
          >
            Upload Image
          </button>
          <button
            className={classes.addEnablePhotobutton}
            onClick={props.onClose}
          >
            Close
          </button>
        </div>
      </Modal>
      }
    </Fragment>
  );
};

export default AddPhotos;
