import React, { Fragment, useState } from "react";
import classes from "./OrganizationalDetail.module.css";
import StepperUI from "../../UI/Stepper/Stepper";

const OrganizationDetail = (props) => {

  const [errormessage,showErrorMessage]=useState('')
  const [successmessage,showSuccessMessage]=useState('')
  const [errormessageemail,showErrorMessageemail]=useState('')
  const [successmessageemail,showSuccessMessageemail]=useState('')
  const [errormessagepassword,showErrorMessagepassword]=useState('')  
  const [errormessageorgname,showErrorMessageorgname]=useState('')
  const [errormessagelob,showErrorMessagelob]=useState('')

  const handleInputChange = (event) => {
    if(event.target.id==='orgemail'){
        let email=event.target.value
        if(email.includes('@')){
            showSuccessMessageemail('Correct Email Address')
            showErrorMessageemail('')
            props.handleChange(event.target.id,email.trim());
            
        }else{
            showErrorMessageemail('Please Enter correct Email Address')
            showSuccessMessageemail('')
            
        }
    }
    if(event.target.id==='orgPassword'){
        let password=event.target.value
        if(password.length>=6){
            console.log('password')
            props.handleChange(event.target.id,password.trim());
            showErrorMessagepassword('')
            
        }else{
            showErrorMessagepassword('Please Enter Password having 6 character or more.')
            
        }
         
    }
    if(event.target.id==='orgname'){
        let orgname=event.target.value;
        if(orgname.length>2){
            props.handleChange(event.target.id,orgname);
            showErrorMessageorgname('')
        }else{
            showErrorMessageorgname('Please Enter Organization Name.')
            
        }
        props.handleChange(event.target.id,event.target.value); 
    }
    if(event.target.id==='lob'){
        let lob=event.target.value;
        if(lob.length>5){
            props.handleChange(event.target.id,lob);
            showErrorMessagelob('')
        }else{
          showErrorMessagelob('Please Enter Line Of Business.')
        }
    }
  };
  const nextPage = (event) => {
      event.preventDefault();
    if(props.values.orgemail && props.values.orgPassword && props.values.orgname && props.values.lob){
        props.nextStep();
    }else{
        showErrorMessage('Please Enter Required Field Values marked with *.')
        showSuccessMessage('')  
    }
  };
  return (
    <Fragment>
      <div className={classes.odcontainer}>
        <div className={classes.odscreen}>
          <div className={classes["screen__content"]}>
            <form className={classes.orgDetail}>
                <StepperUI active='1'/>
                <h2 style={{'textAlign':'center'}} className={classes.orgDetailHeading}>Organizational Detail </h2>
              <div className={classes["od__field"]}>
              <label className={classes.odlabel}>Organization Name *</label>
              <input
                id="orgname"
                type="text"
                placeholder="Organization Name"
                className={classes["od__input"]}
                onChange={handleInputChange}
                defaultValue={props.values.orgname}
              />
              {errormessageorgname &&
              <div className={classes.errormessage}>{errormessageorgname}</div>}
              </div>
              <div className={classes["od__field"]}>
              <label className={classes.odlabel}>Organization Email *</label>
              <input
                id="orgemail"
                type="email"
                placeholder="Organization Email"
                className={classes["od__input"]}
                onChange={handleInputChange}
                defaultValue={props.values.orgemail}
              />
              {errormessageemail &&
              <div className={classes.errormessage}>{errormessageemail}</div>}
              {successmessageemail &&
              <div className={classes.successmessage}>{successmessageemail}</div>}
              </div>
              <div className={classes["od__field"]}>
              <label className={classes.odlabel}>Organization Password *</label>
              <input
                id="orgPassword"
                type="password"
                placeholder="Organization Password"
                className={classes["od__input"]}
                onChange={handleInputChange}
                defaultValue={props.values.orgPassword}
              />
              {errormessagepassword &&
              <div className={classes.errormessage}>{errormessagepassword}</div>}
              </div>
              <div className={classes["od__field"]}>
              <label className={classes.odlabel}>Line of Business *</label>
              <textarea
                id="lob"
                placeholder="Line Of Business"
                className={classes.odtextarea}
                onChange={handleInputChange}
                defaultValue={props.values.lob}
              />
              {errormessagelob &&
              <div className={classes.errormessage}>{errormessagelob}</div>}
              </div>
              {errormessage &&
              <div className={classes.errormessage}>{errormessage}</div>}
              {successmessage &&
              <div className={classes.successmessage}>{successmessage}</div>}
              <div className={classes.odbutton}>
              <button className={classes['od__submit']} onClick={nextPage}>Save And Continue</button>
              </div>
            </form>
          </div>
          <div className={classes["screen__background"]}>
            <span className={classes["screen__background__shape4"]}></span>
            <span className={classes["screen__background__shape3"]}></span>
            <span className={classes["screen__background__shape2"]}></span> 
            <span className={classes["screen__background__shape1"]}></span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrganizationDetail;
