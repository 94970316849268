import React, { Fragment } from "react";
import classes from "./MemberDisplay.module.css";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const MemberDisplay = (props) => {
  console.log(props);
  return (
    <Fragment>
          <div className={classes.card}>
            <img
              className={classes.memberImage}
              src={props.user.image}
              alt="Avatar"
            />
            <div className={classes.containermember}>
              <h4>
                <b>{props.user.orgname}</b>
              </h4>
              <div className={classes.contactIcon}>
                <EmailIcon/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <LocalPhoneIcon/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <WhatsAppIcon/>
              </div>
            </div>
          </div>
          <br/>
      {/* <h2>{props.user.prezname}</h2> */}
    </Fragment>
  );
};
export default MemberDisplay;
