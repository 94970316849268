import { Fragment, useEffect, useState } from "react";
import   "./PhotoGalleryViewer.css";
import ImageGallery from "react-image-gallery";
//import   "./Ligntbox.css";
import Carousel from "react-material-ui-carousel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
//import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const PhotoGalleryViewer = (props) => {
  const [galleryimages, setgalleryimages] = useState([]); 
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const[images, setImages] = useState([])
  //let images=[];
  let displayImages = [];
  useEffect(() => {
    setgalleryimages(Object.values(props.gallery.images));
  }, [props.id]);
  return (
    <Fragment>
      <div>
        <article
          className="Post"
          style={{ marginBottom: "15px", marginTop: "15px" }}
        >
          <header>
            <div className="Post-user">
              <div className="Post-user-nickname">
              </div>
            </div>
          </header>

          <div className="Post-image">
              <Carousel autoPlay={false}>
                {galleryimages.map((image) => {
                  console.log(image);
                  return (
                    <img
                      src={image}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      className="lightbox-image"
                    />
                  );
                })}
              </Carousel>
            
          </div>

          <div className="Post-caption">
            <strong> {props.gallery.caption} </strong> 
          </div>
        </article>
        {isOpen && (
          <Lightbox
            
            mainSrc={galleryimages[photoIndex]}
            nextSrc={galleryimages[(photoIndex + 1) % galleryimages.length]}
            prevSrc={galleryimages[(photoIndex + galleryimages.length - 1) % galleryimages.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + galleryimages.length - 1) % galleryimages.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % galleryimages.length)}
            reactModalStyle={{'opacity': '0'}}
            />
        )}
        
      </div>
    </Fragment>
  );
};

export default PhotoGalleryViewer;
