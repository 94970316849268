import React from "react";
import { Fragment, useEffect, useState, useRef, useContext } from "react";
import classes from "./UserCheck.module.css";
import { db } from "../../store/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useNavigate } from "react-router-dom";
// import Signup from '../AuthForm/Signup.js';
import MessageIcon from "@mui/icons-material/Message";
import AuthContext from "../../store/auth-context";
import Loading from "../UI/Loading/Loading";
import Logo from '../AuthForm/Login/Dhanbad_Club_Logo.png'
const UserCheck = () => {
  const axios = require("axios");
  const authctx = useContext(AuthContext);
  const usersCollectionRef = collection(db, "users");
  const [users, setUsers] = useState([]);
  const enteredInputRefId = useRef();
  const enteredRefOTP = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOTPMessage, setOTPErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [OTP, setOTP] = useState();
  const [unregisteredUser, setUnregisteredUser] = useState({});
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();
  let finaluser, registeredUser, phoneNum, enteredId;

  useEffect(() => {
    const getUsers = async () => {
      isLoading(true);
      const data = await getDocs(usersCollectionRef);
      console.log(data);
      setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      isLoading(false);
    };
    getUsers();
  }, []);

  const generateOTP=()=>{
    var digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 4; i++ ) {
        OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  }

  const formSubmitHandler = (event) => {
    setErrorMessage("");
    setSuccessMessage("");
    event.preventDefault();
    enteredId = enteredInputRefId.current.value.toUpperCase();
    let enteredType = enteredId.concat("", "t");
    console.log(users);
    users.filter((user) => {
      console.log(user.id);
      if (Object.keys(user).includes(enteredId)) {
        finaluser = user[enteredId];
        phoneNum = user[enteredId];
        console.log("finaaallUsseerr", finaluser);
        console.log("finaaal", phoneNum);
        if (+finaluser) {
          console.log("number");
          setUnregisteredUser({
            memberId: enteredId,
            phoneNumber: user[enteredId],
            type: user[enteredType],
            id: user.id,
          });
        } else {
          registeredUser = "r";
        }
      }
      return finaluser;
    });
    if (finaluser) {
      if (registeredUser) {
        setSuccessMessage("Member Already Exists!!");
        navigate("/login", { replace: true });
      } else {
        let setNewOTP = generateOTP();
        setOTP(setNewOTP);
        setSuccessMessage("Member Exist but Not Registered!!");
        let message = `Dear JITA User, OTP is ${setNewOTP} for Registration in JITA App vide Membership No. ${enteredId}.`;
        //let message = `Dear User, OTP is ${setNewOTP} for Registration in Dhanbad Club With Membership No. ${enteredId}.`;
        axios
          .get(
            `https://smshorizon.co.in/api/sendsms.php?user=pankhudisoftware&apikey=Df8MOFEsDLDp9ZLsECPt&mobile=${phoneNum}&message=${message}&senderid=JITAIN&type=txt&tid=1207161763243940164`
          )
          .then((res) => {
            console.log(res);
            console.log("hiiiaaaa");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setErrorMessage("Member Id doesn't exists");
    }
  };

  const memberSignUp = (event) => {
    setOTPErrorMessage("");
    let enteredOTP = enteredRefOTP.current.value;
    if (OTP === enteredOTP) {
      authctx.checkMember(unregisteredUser.memberId);
      if (unregisteredUser) {
        navigate("/signup", { replace: true, state: { unregisteredUser } });
      }
    } else {
      console.log(OTP);
      event.preventDefault();
      setOTPErrorMessage("Please Enter Correct OTP");
    }
  };

  const redirecttoLogin = () => {
    navigate("/login", { replace: true });
  };

  return (
    <Fragment>
      {loading && <Loading />}
      {!loading && (
        <div className={classes.uccontainer}>
          <div className={classes.ucscreen}>
            <div className={classes["screen__content"]}>
              <form
                className={classes.uclogin}
                onSubmit={OTP ? memberSignUp : formSubmitHandler}
              >
                <img src={Logo} height={100} width={300}/>
                <h2 className={classes.ucheading}>Member Registration</h2>
                <div className={classes["login__field"]}>
                  <input
                    type="text"
                    className={classes["login__input"]}
                    placeholder="Enter Member Id"
                    ref={enteredInputRefId}
                  />
                  <PermIdentityIcon />
                </div>
                {errorMessage && (
                  <div className={classes.errormessage}>{errorMessage}</div>
                )}
                {successMessage && (
                  <div className={classes.successmessage}>{successMessage}</div>
                )}
                {successMessage && (
                  <div className={classes["login__field"]}>
                    <input
                      type="number"
                      className={classes["login__input"]}
                      placeholder="Enter OTP"
                      ref={enteredRefOTP}
                    />
                    <MessageIcon />
                  </div>
                )}
                {errorOTPMessage && (
                  <div className={classes.errormessage}>{errorOTPMessage}</div>
                )}
                <button className={classes["login__submit"]} type="submit">
                  <span className={classes["button__text"]}>Submit</span>
                  <ArrowForwardIcon />
                </button>
                <button
                  className={classes["login__submit"]}
                  type="button"
                  onClick={redirecttoLogin}
                >
                  <span className={classes["button__text"]}>Login</span>
                  <ArrowForwardIcon />
                </button>
              </form>
            </div>
            <div className={classes["screen__background"]}>
              <span className={classes["screen__background__shape4"]}></span>
              <span className={classes["screen__background__shape3"]}></span>
              <span className={classes["screen__background__shape2"]}></span>
              <span className={classes["screen__background__shape1"]}></span>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default UserCheck;
