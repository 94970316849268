import React, { Fragment, useContext, useEffect, useState } from "react";
import { db } from "../../store/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import AuthContext from "../../store/auth-context";
import classes from "./Profile.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Loading from '../UI/Loading/Loading'; 
import SideBar from "../UI/Sidebar/SideBar";
import ProfileImageUpload from '../ProfileImageUpload/ProfileImageUpload'
import {auth} from "../../store/firebase-config"
import { onAuthStateChanged } from "firebase/auth";
import Layout from "../Layout/Layout";

const Profile = () => {
  const authctx = useContext(AuthContext);
  //console.log(JSON.parse(authctx.loggedInUserId));
  const usersRef = doc(db, "regUsers", authctx.loggedInUserId);
  const [user, setUser] = useState({});
  const [modalShow,SetModalShow]=useState(false);
  const [loading,isLoading]=useState(false);
  const [currentUser,setCurrentUser]=useState('');
  const getUserData = async () => {
    isLoading(true)
    let docdata = await getDoc(usersRef);
    if (docdata.exists()) {
     
      console.log(docdata.data());
      setUser(docdata.data());
      isLoading(false)
    } else {
      console.log("No such document");
    }
  };
  useEffect(() => {
    getUserData();
  }, [modalShow,currentUser]);

  useEffect(()=>{
    const currUser=onAuthStateChanged(auth,user=>setCurrentUser(user))
    return currUser
  },[currentUser])

  const showprofileImageChange=()=>{
    console.log('hiiiii')
    SetModalShow(true)
  }
  const hideprofileImageChange=()=>{
    SetModalShow(false)
  }

  return (  
    <Layout>
      {loading && !modalShow && <Loading/>}
      {!loading && modalShow &&  <ProfileImageUpload onClose={hideprofileImageChange} defaultimage={user.image}/>}
      {!loading && !modalShow &&
      <SideBar>
      <div className={classes.profilecontainer}> 
        <div className={classes.firstpart}>
          <div className={classes.profileimagediv}>
            <img className={classes.profileimage} src={user.image} alt="Profile" onClick={showprofileImageChange} />
          </div>
          <div className={classes.header}>{user.namepartner1}</div>
          <div className={classes.personalDetail}>
          <div className={classes.detail}>
          <div className={classes.personalDetailtitle}>Personal Details</div>
            Email: {user.orgemail}
            <br />
            Member Id: {user.mid}
            <br />
          </div>
          </div>
        </div>
        <br></br>
        <hr></hr>
        <div className={classes.secondpart}>
          <div className={classes.title}>Organization Details</div>
          <div className={classes.detail}> 
            Organization Name: {user.orgname}
            <br />
            Organization Type: {user.orgtype}
            <br />
            Description : {user.lob}
          </div>
          <br />
          <br />
          <div className={classes.title}>Contact Details</div>
          <div className={classes.detail}>
            Office Phone Number: {user.orgphone}
            <br />
            

            <button className={classes["login__submit"]} type="submit">
                <span className={classes["button__text"]}>Update Profile</span>
                <ArrowForwardIcon />
            </button>
          </div>
        </div>
      </div>
      </SideBar>
     }
    </Layout>
  );
};

export default Profile;
