import { useContext } from "react";
import { Route,Routes,Navigate } from "react-router-dom";
import Login from "./Components/AuthForm/Login/Login";
import AuthContext from "./store/auth-context";
import UserCheck from "./Components/UserRegistrationCheck/UserCheck";
import Profile from "./Components/Profile/Profile";
import MemberDirectory from "./Components/Member Directory/MemberDirectory";
import PhotoGallery from "./Components/PhotoGallery/PhotoGallery";
import Donation from "./Components/Donation/Donation";
import SignupForOrg from "./Components/AuthForm/Signup/SignupForOrg";
const  App =()=> {
  const authctx=useContext(AuthContext);
  console.log(authctx.loggedInUserId)
  console.log(authctx.isLoggedIn)
  return (
    
      <Routes>
        <Route path="/login" element={!authctx.isLoggedIn ?<Login/>:<Navigate replace to="/profile"/>}/>
        <Route path="/signup" element={authctx.isMemberExist ?<SignupForOrg/>:<Navigate replace to="/login"/>}/>       
        <Route path="/usercheck" element={!authctx.isLoggedIn ?<UserCheck/>:<Navigate replace to="/login"/>}/>
        <Route path="/profile" element={authctx.isLoggedIn ?<Profile/>:<Navigate replace to="/login"/>}/>
        <Route path="/photogallery" element={authctx.isLoggedIn ?<PhotoGallery/>:<Navigate replace to="/login"/>}/>
        <Route path="/donation" element={authctx.isLoggedIn ?<Donation/>:<Navigate replace to="/login"/>}/>
        <Route path="/memberdirectory" element={authctx.isLoggedIn ?<MemberDirectory/>:<Navigate replace to="/login"/>}/>
        <Route path="*" element={!authctx.isLoggedIn ?<Login/>:<Navigate replace to="/profile"/>}/>
      </Routes>
       
   
    
  );
}

export default App;
