import { Fragment,useState,useEffect } from "react";
import classes from './ProfileImageUpload.module.css'
import Modal from '../UI/Modal/Modal'
import {auth} from "../../store/firebase-config"
import { onAuthStateChanged } from "firebase/auth";
import {getStorage,ref,uploadBytes,getDownloadURL} from "firebase/storage"
import { updateProfile } from "firebase/auth";
import Loading from "../UI/Loading/Loading";
import {updateDoc,doc} from "firebase/firestore"
import { db } from "../../store/firebase-config";

const ProfileImageUpload = (props) => {
  
  const [file,isfile]=useState(null);
  const [currentUser,setCurrentUser]=useState('');
  const [photoURL,setPhotoURL]=useState(props.defaultimage)
  const [loading,isLoading]=useState(false)
  const storage=getStorage();
  
  const onImageChange=(event)=>{
   if(event.target.files[0]){ 
    let uploadedfiles=event.target.files[0];
    if(uploadedfiles.type.includes('image')){
        isfile(event.target.files[0]);
    }
    console.log(event.target.files[0])
  }
}

  const uploadfile=()=>{
    document.getElementById("getUploadFile").click()
  }

  const handleUpload=async()=>{
    updateProfile(currentUser,{photoURL:null})
    const fileRef=ref(storage,'users/'+currentUser.uid)
    isLoading(true)
    const snapshot= await uploadBytes(fileRef,file)
    const photoURL= await getDownloadURL(fileRef)
  
    const userDoc = doc(db, "regUsers", currentUser.uid);
    const newFields = { image:  photoURL};
    await updateDoc(userDoc, newFields);
    updateProfile(currentUser,{photoURL:photoURL})
    isLoading(false)
    props.onClose()
  }

  useEffect(()=>{
    const currUser=onAuthStateChanged(auth,user=>setCurrentUser(user))
    return currUser
  },[])

  useEffect(()=>{
    if(currentUser?.photoURL){
      setPhotoURL(currentUser.photoURL)
    }
    console.log(currentUser)
  },[currentUser])

  return (
    <Fragment>
      {loading && <Loading/>}
      
      {!loading &&
        <Modal onClose={props.onClose}>
          <h1 className={classes.profileModalHeader}>Profile Image</h1>
          <img className={classes.profileUploadImage} src={file?URL.createObjectURL(file) : photoURL} alt="Profile" />
          <br></br><br></br>
          <div className={classes.actions}>
          <button className={classes.enabledbutton}  onClick={uploadfile}>
            Select Photo
          </button>
          <input className={classes.profileImageInputFile} id="getUploadFile" type="file" name="Upload File" accept="image/*" onChange={onImageChange}/>
          <button className={file?classes.enabledbutton:classes.disabledbutton} disabled={!file}  onClick={props.onClose && handleUpload}>
            Upload File
          </button>
          <button className={classes.enabledbutton} onClick={props.onClose}>
            Close
          </button>
        </div>
        </Modal>
      }
    </Fragment>
  );
};
export default ProfileImageUpload;
