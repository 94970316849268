import React, { Fragment, useState, useEffect } from "react";
import classes from "./OtherOrgDetail.module.css";
import StepperUI from "../../UI/Stepper/Stepper";
const OtherOrgDetail = (props) => {
  const [orgType, seletedOrgType] = useState(props.values.orgtype);
  //Partner
  const [noofpartners, setnoofpartners] = useState(props.values.noofpartners);
  const [partnerfields, setpartnerpartnerfields] = useState([]);
  let partnerelement = [];
  //Director
  const [noofdirector, setnoofdirector] = useState(props.values.noofpartners);
  const [directorfields, setdirectorfields] = useState([]);
  let directorelement = [];
  const [errormessage, showErrorMessage] = useState("");
  const [orggstin, setorggstin] = useState(false);

  const district = [
    "None",
    "Bokaro",
    "Dhanbad",
    "Chatra",
    "Deoghar",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribag",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahibganj",
    "Seraikela-Kharsawan",
    "Simdega",
    "West Singhbhum",
  ];

  console.log(props.checkUser.type);
  const handleInputChange = (event) => {
    console.log(event.target.value);
    console.log(event.target.id);
    if (event.target.id === "orgtype") {
      seletedOrgType(event.target.value);
      props.handleChange(event.target.id, event.target.value);
    }
    if (event.target.id === "noofpartners") {
      setnoofpartners(event.target.value);
      props.handleChange(event.target.id, event.target.value);
    }
    if (event.target.id === "noofdirectors") {
      setnoofdirector(event.target.value);
      props.handleChange(event.target.id, event.target.value);
    }
    if (event.target.id === "orggstinreg") {
      setorggstin(event.target.checked);
      if (event.target.checked) {
        props.handleChange(event.target.id, "true");
      } else {
        props.handleChange(event.target.id, "false");
      }
    }
    props.handleChange(event.target.id, event.target.value);
  };

  useEffect(() => {
    if (!orggstin) {
      // if(props.values.orggstinreg){
      //   delete props.values.orggstinreg;
      // }
      if (props.values.orggstin) {
        delete props.values.orggstin;
      }
    }
  }, [orggstin]);

  useEffect(() => {
    if (noofpartners >= 0) {
      if (partnerfields) {
        partnerfields.forEach((e) => {
          delete props.values[`namepartner${e.id}`];
          delete props.values[`contactpartner${e.id}`];
        });
      }

      for (let i = 0; i < noofpartners; i++) {
        partnerelement.push({
          id: i + 1,
          val: "Partners",
        });
      }
      setpartnerpartnerfields(partnerelement);
    }
  }, [noofpartners]);

  useEffect(() => {
    if (noofdirector >= 0) {
      if (directorfields) {
        directorfields.forEach((e) => {
          delete props.values[`namedirector${e.id}`];
          delete props.values[`contactdirector${e.id}`];
        });
      }

      for (let i = 0; i < noofdirector; i++) {
        directorelement.push({
          id: i + 1,
          val: "Directors",
        });
      }
      setdirectorfields(directorelement);
    }
  }, [noofdirector]);

  useEffect(() => {
    if (noofpartners >= 0) {
      if (partnerfields) {
        partnerfields.forEach((e) => {
          delete props.values[`namepartner${e.id}`];
          delete props.values[`contactpartner${e.id}`];
        });
      }
    }
    if (props.values.noofpartners) {
      delete props.values.noofpartners;
    }
    //director
    if (noofdirector >= 0) {
      if (directorfields) {
        directorfields.forEach((e) => {
          delete props.values[`namedirector${e.id}`];
          delete props.values[`contactdirector${e.id}`];
        });
      }
    }
    if (props.values.noofdirectors) {
      delete props.values.noofdirectors;
    }
    if (props.values.orgsingleusername) {
      delete props.values.orgsingleusername;
    }
    if (props.values.orgsingleusercontact) {
      delete props.values.orgsingleusercontact;
    }
    setnoofpartners(0);
    setnoofdirector(0);
  }, [orgType]);

  useEffect(() => {
    props.handleChange("orgtype", orgType);
  }, []);

  const nextPage = (event) => {
    event.preventDefault();
    if (
      props.values.orgtype !== "None" &&
      props.values.orgaddress &&
      props.values.orgdistrict &&
      props.values.orgpincode
    ) {
      props.nextStep();
    } else {
      showErrorMessage("Please Enter all fields marked with *");
    }
  };
  const prevPage = () => {
    props.prevStep();
  };
  return (
    <Fragment>
      <div className={classes.oodcontainer}>
        <div className={classes.oodscreen}>
          <div className={classes["screen__content"]}>
            <form className={classes.oorgDetail}>
            <StepperUI active='2'/>
              <h2
                style={{ textAlign: "center" }}
                className={classes.oorgDetailHeading}
              >
                Other Organizational Detail
              </h2>
              <h4 style={{ textAlign: "center", color: "red" }}>
                Please Enter all fields marked with *
              </h4>
              <div className={classes["ood__field"]}>
                <label className={classes.oodlabel}>
                  Organization Category
                </label>
                <input
                  id="orgcategory"
                  type="text"
                  className={classes["ood__input"]}
                  defaultValue={props.checkUser.type}
                  readOnly={true}
                />
              </div>
              {props.checkUser.type !== "Affiliation Member" && (
                <div className={classes["ood__field"]}>
                  <label className={classes.oodlabel}>Organization Type*</label>
                  <select
                    className={classes["ood__input"]}
                    id="orgtype"
                    autoFocus={true}
                    onChange={handleInputChange}
                    // defaultValue={props.value.orgtype}
                  >
                    <option value="None">None</option>
                    <option value="Proprietor">Proprietor</option>
                    <option value="Partnership Firm">Partnership Firm</option>
                    <option value="Limited Liability Company">
                      Limited Liability Company
                    </option>
                    <option value="Corporation">Corporation</option>
                  </select>
                </div>
              )}
              {/* Partner */}
              {props.values.orgtype === "Partnership Firm" && (
                <div className={classes["ood__field"]}>
                  <label className={classes.oodlabel}>No of Partners</label>
                  <input
                    id="noofpartners"
                    type="text"
                    className={classes["ood__input"]}
                    defaultValue={props.values.noofpartners}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {props.values.orgtype === "Partnership Firm" &&
                noofpartners > 0 &&
                partnerfields.map((partner) => {
                  return (
                    <div key={partner.id}>
                      <label className={classes.oodlabel}>
                        {`Partner ${partner.id}`}:{" "}
                      </label>
                      <br />
                      <div className={classes.partner}>
                        <label className={classes.oodlabel}>Name</label>
                        <input
                          id={`namepartner${partner.id}`}
                          type="text"
                          className={classes["ood__input"]}
                          onChange={handleInputChange}
                          //defaultValue={props.values[`namepartner${partner.id}`]}
                        />
                        <label className={classes.oodlabel}>
                          Contact Number
                        </label>
                        <input
                          id={`contactpartner${partner.id}`}
                          type="text"
                          className={classes["ood__input"]}
                          onChange={handleInputChange}
                          //defaultValue={props.values[`contactpartner${partner.id}`]}
                        />
                      </div>
                    </div>
                  );
                })}
              {/* Director */}
              {props.values.orgtype === "Limited Liability Company" && (
                <div className={classes["ood__field"]}>
                  <label className={classes.oodlabel}>No of Directors</label>
                  <input
                    id="noofdirectors"
                    type="text"
                    className={classes["ood__input"]}
                    defaultValue={props.values.noofdirectors}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {props.values.orgtype === "Limited Liability Company" &&
                noofdirector > 0 &&
                directorfields.map((director) => {
                  return (
                    <div key={director.id}>
                      <label className={classes.oodlabel}>
                        {`Director ${director.id}`}:{" "}
                      </label>
                      <br />
                      <div className={classes.partner}>
                        <label className={classes.oodlabel}>Name</label>
                        <input
                          id={`namedirector${director.id}`}
                          type="text"
                          className={classes["ood__input"]}
                          onChange={handleInputChange}
                          //defaultValue={props.values[`namepartner${partner.id}`]}
                        />
                        <label className={classes.oodlabel}>
                          Contact Number
                        </label>
                        <input
                          id={`contactdirector${director.id}`}
                          type="text"
                          className={classes["ood__input"]}
                          onChange={handleInputChange}
                          //defaultValue={props.values[`contactpartner${partner.id}`]}
                        />
                      </div>
                    </div>
                  );
                })}

              {props.checkUser.type === "Affiliation Member" && (
                <div>
                  <div className={classes["ood__field"]}>
                    <label className={classes.oodlabel}>President</label>
                    <input
                      id="orgpresident"
                      type="text"
                      className={classes["ood__input"]}
                      defaultValue={props.values.orgpresident}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className={classes["ood__field"]}>
                    <label className={classes.oodlabel}>Secretary</label>
                    <input
                      id="orgsecretary"
                      type="text"
                      className={classes["ood__input"]}
                      defaultValue={props.values.orgsecretary}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className={classes["ood__field"]}>
                    <label className={classes.oodlabel}>Treasurer</label>
                    <input
                      id="orgtreasurer"
                      type="text"
                      className={classes["ood__input"]}
                      defaultValue={props.values.orgtreasurer}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}

              {(props.values.orgtype === "Proprietor" ||
                props.values.orgtype === "Corporation") && (
                <div>
                  <div className={classes["ood__field"]}>
                    <label className={classes.oodlabel}>Member Id</label>
                    <input
                      id="orgmid"
                      type="text"
                      className={classes["ood__input"]}
                      defaultValue={props.checkUser.memberId}
                      readOnly={true}
                    />
                  </div>
                  <div className={classes["ood__field"]}>
                    <label className={classes.oodlabel}>Name</label>
                    <input
                      id="orgsingleusername"
                      type="text"
                      className={classes["ood__input"]}
                      defaultValue={props.values.orgsingleusername}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className={classes["ood__field"]}>
                    <label className={classes.oodlabel}>Contact Number</label>
                    <input
                      id="orgsingleusercontact"
                      type="text"
                      className={classes["ood__input"]}
                      defaultValue={props.values.orgsingleusercontact}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}

              <div className={classes["ood__field"]}>
                <label className={classes.oodlabel}>
                  Organization Address *
                </label>
                <textarea
                  id="orgaddress"
                  placeholder="Organization Address"
                  className={classes.odtextarea}
                  onChange={handleInputChange}
                  defaultValue={props.values.orgaddress}
                />
              </div>

              <div className={classes["ood__field"]}>
                <label className={classes.oodlabel}>Organization State</label>
                <input
                  id="orgstate"
                  type="text"
                  className={classes["ood__input"]}
                  defaultValue="JHARKHAND"
                  readOnly={true}
                />
              </div>

              <div className={classes["ood__field"]}>
                <label className={classes.oodlabel}>
                  Organization DISTRICT*
                </label>
                <select
                  className={classes["ood__input"]}
                  id="orgdistrict"
                  autoFocus={true}
                  onChange={handleInputChange}
                  // defaultValue={props.value.orgtype}
                >
                  {district.map((dis) => {
                    return <option value={dis}>{dis}</option>;
                  })}
                </select>
              </div>

              <div className={classes["ood__field"]}>
                <label className={classes.oodlabel}>
                  Organization PINCODE*
                </label>
                <input
                  id="orgpincode"
                  type="text"
                  placeholder="Enter 6 digit code"
                  className={classes["ood__input"]}
                  defaultValue={props.values.orgpincode}
                  onChange={handleInputChange}
                />
              </div>

              <div className={classes["ood__field"]}>
                <label className={classes.oodlabel}>GSTIN Registered*</label>
                <input
                  id="orggstinreg"
                  type="checkbox"
                  onChange={handleInputChange}
                />
              </div>

              {orggstin && (
                <div className={classes["ood__field"]}>
                  <label className={classes.oodlabel}>
                    Organizational GSTIN
                  </label>
                  <input
                    id="orggstin"
                    type="text"
                    className={classes["ood__input"]}
                    placeholder="Organizational GSTIN"
                    defaultValue={props.values.orggstin}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {errormessage && (
                <div className={classes.errormessage}>{errormessage}</div>
              )}
              <div className={classes.odbutton}>
                <button className={classes["ood__submit"]} onClick={prevPage}>
                  Previous Page
                </button>
              </div>
              <div className={classes.odbutton}>
                <button className={classes["ood__submit"]} onClick={nextPage}>
                  Save And Continue
                </button>
              </div>
            </form>
          </div>
          <div className={classes["screen__background"]}>
            <span className={classes["screen__background__shape4"]}></span>
            <span className={classes["screen__background__shape3"]}></span>
            <span className={classes["screen__background__shape2"]}></span>
            <span className={classes["screen__background__shape1"]}></span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OtherOrgDetail;
